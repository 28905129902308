import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React, { Suspense, useEffect, useState } from 'react';
import './App.css';

import * as constant from './constants';

import 'bootstrap/dist/css/bootstrap.min.css';
import { Container } from 'react-bootstrap';

import axios from 'axios';

import { Routes, Route } from "react-router-dom";
import Loader from './component/loader/Loader';

const Login = React.lazy(() => import("./component/login/Login"));
const Profile = React.lazy(() => import("./component/profile/Profile"));
const Dashboard = React.lazy(() => import("./component/dashboard/Dashboard"));
const UsersManagement = React.lazy(() => import("./component/userManagement/UsersManagement"));
const Notices = React.lazy(() => import("./component/notices/Notices"));
const HoursWorked = React.lazy(() => import("./component/hoursWorked/HoursWorked"));
const Payslips = React.lazy(() => import("./component/payslips/Payslips"));
const Documents = React.lazy(() => import("./component/documents/Documents"));
const ResetPassword = React.lazy(() => import("./component/resetPassword/ResetPassword"));
const Error404 = React.lazy(() => import("./component/errorPage/Error404"));
const ClientsModulesDashboard = React.lazy(() => import("./component/clients/ClientsModulesDashboard"));
const ClientModules = React.lazy(() => import("./component/clientModules/ClientModules"));
const ClientsReferents = React.lazy(() => import("./component/clientsReferents/ClientsReferents"));
const WorkOrder = React.lazy(() => import("./component/module/workOrder/WorkOrder"));

function App() {

  const [isLogged, setIsLogged] = useState(false);
  const [user, setUser] = useState([]);
  const [loadingUser, setLoadingUser] = useState(false);
  const [logoutFlag, setLogoutFlag] = useState(false);

  const [tokenApi, setTokenApi] = useState(() => {
    let tokenSetted = window.sessionStorage.getItem("tokenApi");

    if(typeof tokenSetted != "undefined" && tokenSetted != null && tokenSetted.length > 0)
    {
      return false;
    }

    let token = '';
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;

    for( let i = 0; i < 60; i++ ) 
    {
      token += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    window.sessionStorage.setItem('tokenApi', token);

    return token;
});


  let timeoutEnergySaving;
  let timeoutLogout;

  
  //listener event mouse for add-remove energy saving mode and for do a logout
  const listenerMouseMove = () => {

    document.getElementsByTagName("html")[0].classList.remove("energySaving");

    clearTimeout(timeoutEnergySaving);
    clearTimeout(timeoutLogout);
    
    timeoutEnergySaving = setTimeout(activeEnergySaving, constant.TIME_ENERGY_SAVING);

    timeoutLogout = setTimeout(logout, constant.EXPIRED_SESSION * 60 * 1000);
  };


  document.addEventListener('mousemove', listenerMouseMove, false);
  document.addEventListener('scroll', listenerMouseMove, false);


  //method for add class energySaving
  const activeEnergySaving = () => {
    document.getElementsByTagName("html")[0].classList.add("energySaving");
  };


  useEffect(() => {
    let tokenSetted = window.sessionStorage.getItem("tokenApi");

    if(typeof tokenSetted != "undefined" && tokenSetted != null && tokenSetted.length > 0)
    {
      return false;
    }

    let token = '';
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;

    for( let i = 0; i < 60; i++ ) 
    {
      token += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    window.sessionStorage.setItem('tokenApi', token);

    setTokenApi(token);

    return true;
    
 }, [tokenApi])


  //check if change version and if it changed delete a cache
  useEffect(() =>
  {
    let versionSoftwareBrowser = localStorage.getItem("version");
    if(typeof versionSoftwareBrowser === "undefined" || versionSoftwareBrowser === null || versionSoftwareBrowser.length <= 0)
    {
      versionSoftwareBrowser = constant.VERSION;
      localStorage.setItem("version", versionSoftwareBrowser);
    }

    if(versionSoftwareBrowser !== constant.VERSION)
    {
      clearCacheData();

      versionSoftwareBrowser = constant.VERSION;
      localStorage.setItem("version", versionSoftwareBrowser);
    }

    return true;
  }, []);


  //check if user is logged
  useEffect(() =>
  {
    loadUser();
  }, []);


  //reload user if isLogged change
  useEffect(() =>
  {
    loadUser();
  }, [isLogged]);


  //load user logged
  const loadUser = () => 
  {
    if(loadingUser)
    {
      return false;
    }

    setLoadingUser(true);

    //array with data
    const data = {
      token: window.sessionStorage.getItem("tokenApi")
    };
    
    axios.post(constant.BASE_URL + constant.FOLDER + constant.FOLDER_BACKEND + constant.FOLDER_API + 'getUser.php', data)
    .then(res => {
      if(!res.data.err && typeof res.data.user !== "undefined" && res.data.user.hasOwnProperty("id") && res.data.user.id.length > 0)
      {
        setUser(res.data.user);

        if(!isLogged)
        {
          setIsLogged(true);
        }
        
        if(window.location.href.includes("/login") || window.location.href.includes("/reset-password"))
        {
          window.location.href = constant.BASE_URL + "dashboard";
        }
      }
      else
      {
        setUser([]);

        if(isLogged)
        {
          setIsLogged(false);
        }
        
        if(!window.location.href.includes("/login") && !window.location.href.includes("/reset-password"))
        {
          window.location.href = constant.BASE_URL + "login";
        }
        
      }
    })
    .catch(error => {
      setUser([]);
      setIsLogged(false);
    })
    .finally(() => {
      setLoadingUser(false);
    });
  }


  //set if user is logged
  const checkLogged = (isLogged) =>
  {
    if(typeof isLogged === "undefined")
    {
      return false;
    }

    setIsLogged(isLogged);

    return false;
  }


  //logout method
  const logout = () => 
  {
      if(logoutFlag)
      {
          return false;
      }

      setLogoutFlag(true);

      //array with data
      const data = {
          token: window.sessionStorage.getItem("tokenApi")
      };

      axios.post(constant.BASE_URL + constant.FOLDER + constant.FOLDER_BACKEND + constant.FOLDER_API + 'logout.php', data)
      //api return a result
      .then(res => {
              window.location.href = constant.BASE_URL + "login";
      })
      //api error
      .catch(error => {
          window.alert(error);
      })
      .finally(() => {
          setLogoutFlag(false);
      });


      return true;
  };

  
  //function to clear complete cache data
  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });

    console.log('Cache cleared');
  };


  return (
    <Container fluid className="app">

      <Suspense fallback={<Loader />}>

        <Routes>
          {!isLogged && <Route exact path="" element={<Login checkLogged={checkLogged} />} />}
          <Route exact path="login" element={<Login checkLogged={checkLogged} />} />
          <Route exact path="reset-password" element={<ResetPassword />} />
          {isLogged && <Route exact path="" element={<Dashboard user={user} loadingUser={loadingUser} logout={logout}/>} />}
          <Route exact path="dashboard" element={<Dashboard user={user} loadingUser={loadingUser} logout={logout} />} />
          <Route exact path="hours-worked" element={<HoursWorked user={user} loadingUser={loadingUser} logout={logout}/>} />
          <Route exact path="payslips" element={<Payslips user={user} loadingUser={loadingUser} logout={logout}/>} />
          <Route exact path="documents" element={<Documents user={user} loadingUser={loadingUser} logout={logout}/>} />
          <Route exact path="notices" element={<Notices user={user} loadingUser={loadingUser} logout={logout}/>} />
          <Route exact path="profile" element={<Profile user={user} loadingUser={loadingUser} logout={logout}/>} />
          <Route exact path="users-management" element={<UsersManagement user={user} loadingUser={loadingUser} logout={logout}/>} />
          <Route exact path="clients-modules" element={<ClientsModulesDashboard user={user} loadingUser={loadingUser} logout={logout}/>} />
          <Route exact path="client-modules" element={<ClientModules user={user} loadingUser={loadingUser} logout={logout}/>} />
          <Route path="client-modules/:idClient" element={<ClientModules user={user} loadingUser={loadingUser} logout={logout}/>} />
          <Route exact path="clients-referents" element={<ClientsReferents user={user} loadingUser={loadingUser} logout={logout}/>} />
          <Route path="clients-referents/:idClient" element={<ClientsReferents user={user} loadingUser={loadingUser} logout={logout}/>} />
          <Route path={"module/" + constant.URL_WORK_ORDER} element={<WorkOrder user={user} loadingUser={loadingUser} logout={logout}/>} />
          <Route path="*" element={<Error404 />} />
        </Routes>

      </Suspense>

    </Container>
  );
}

export default App;
