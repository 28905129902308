//version number and date of it
//it is used to keep track of releases and, in case of it, to clear the browser cache
export const VERSION = "1.2.1";
export const DATE = "2022-07-11";


const DEV_MODE = process.env.REACT_DEVELOPMENT_MODE;

let baseUrl = "";
let folder = "";
if(DEV_MODE)
{
    baseUrl = "http://localhost/";
    folder = "user-area-kaffeina/";
}
else
{
    baseUrl = "https://user-area.kaffeina.it/";
    folder = ""
}

export const BASE_URL = baseUrl;

export const FOLDER = folder;

export const EXPIRED_SESSION = 30; //minutes


//backend
export const FOLDER_BACKEND = "src/backend/";
export const FOLDER_API = "api/";


//status
export const ID_STATUS_PENDING = 0;
export const ID_STATUS_ACCEPTED = 1;
export const ID_STATUS_REJECTING = 2;

export const NAME_STATUS_PENDING = "In attesa";
export const NAME_STATUS_ACCEPTED = "Accettato";
export const NAME_STATUS_REJECTING = "Rifiutato";

let array_status = [];
array_status[ID_STATUS_PENDING] = NAME_STATUS_PENDING;
array_status[ID_STATUS_ACCEPTED] = NAME_STATUS_ACCEPTED;
array_status[ID_STATUS_REJECTING] = NAME_STATUS_REJECTING;

export const ARRAY_STATUS = array_status;


//type of event
export const ID_HOURS_WORKED = 0;
export const ID_HOURS_OVERTIME = 1;
export const ID_HOURS_PERMISSION = 2;
export const ID_HOURS_MUTUAL = 3;
export const ID_HOURS_HOLIDAY = 4;

export const NAME_HOURS_WORKED = "Lavoro";
export const NAME_HOURS_OVERTIME = "Extra";
export const NAME_HOURS_PERMISSION = "Permesso";
export const NAME_HOURS_MUTUAL = "Malattia";
export const NAME_HOURS_HOLIDAY = "Ferie";

let array_event = [];
array_event[ID_HOURS_WORKED] = NAME_HOURS_WORKED;
array_event[ID_HOURS_OVERTIME] = NAME_HOURS_OVERTIME;
array_event[ID_HOURS_PERMISSION] = NAME_HOURS_PERMISSION;
array_event[ID_HOURS_MUTUAL] = NAME_HOURS_MUTUAL;
array_event[ID_HOURS_HOLIDAY] = NAME_HOURS_HOLIDAY;

export const ARRAY_EVENT = array_event;


//default working hours
export const ENTRY_MORNING = "09:00";
export const EXIT_MORNING = "13:00";
export const ENTRY_AFTERNOON = "14:00";
export const EXIT_AFTERNOON = "18:00";


//aws
export const AWS_REGION = "eu-central-1";
export const URL_AWS_S3 = "s3." + AWS_REGION + ".amazonaws.com";


//bucket aws
export const NAME_BUCKET_AWS = "kaffeina-user-area";
export const NAME_BUCKET_AWS_USER_AVATAR = "users-avatar/";
export const NAME_BUCKET_AWS_LOGOS = "logos/";
export const NAME_BUCKET_AWS_ICON = "icon/";
export const NAME_BUCKET_AWS_CLIENTS_MODULES_IMAGES = "clients-modules-images/";


//energy saving
export const TIME_ENERGY_SAVING = 120000; //milliseconds (2 minutes)


//clients
export const ID_CHIESI_FARMACEUTICI = 1;

//clients modules
export const ID_WORK_ORDER = 1;

export const NAME_WORK_ORDER = "Work Order";

export const URL_WORK_ORDER = "work-order";

let array_clients_modules = [];
array_clients_modules[ID_WORK_ORDER] = NAME_WORK_ORDER;

export const ARRAY_CLIENTS_MODULES = array_clients_modules;