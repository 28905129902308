import { Col, Row, Spinner } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';

import classes from './Loader.module.css';

const Loader = () =>
{
    return (
        <Row style={{minHeight: '100vh'}}>
            <Col xs={12} className="d-flex justify-content-center align-items-center">
                <Spinner animation="border" className={classes.spinnerLoading}  />
            </Col>
        </Row>
    );

};

export default Loader;